import "./scss/style.scss";
import 'bootstrap';
import { tns } from "../node_modules/tiny-slider/src/tiny-slider";
import { appear } from "../node_modules/jquery.appear/jquery.appear";

function data_dinaanim() {

    $("[data-dinaanim]").each(function() {

        var $this = $(this);

        if ($(window).width() > 767) {
    
            $this.addClass("dinaAnim-invisible");

            $this.appear(function() {

                var delay = ($this.data("dinadelay") ? $this.data("dinadelay") : 1);

                if (delay > 1) $this.css("animation-delay", delay + "ms");

                $this.addClass("dinaAnim-animated");
                $this.addClass('dinaAnim-' + $this.data("dinaanim"));

                setTimeout(function() {

                    $this.addClass("dinaAnim-visible");

                }, delay);

            }, { accX: 0, accY: -150 });
        }
    });
}

$('header').load("header.html");
$('footer').load("footer.html", function(response, status, xhr) {
    data_dinaanim();
});

$('#component-wines').load("component-wines.html", function(response, status, xhr) {
    data_dinaanim();
});

if ($.contains(document.body, document.getElementById('slider-main'))) {

    var slider_main = tns({
        container: '#slider-main',
        items: 1,
        loop: true,
        slideBy: 'page',
        autoplay: false,
        mouseDrag: true,
        speed: 800,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        controls: false,
        navPosition: 'bottom',
        lazyload: true,
    });

    $('.block-banner-main').css({ 'background-image': "url('" + $('#item-1').data('img') + "')" });

    slider_main.events.on('indexChanged', function(events) {

        var img = $("#item-" + events.displayIndex).data('img');

        $('.block-banner-main').animate({ opacity: 0 }, 300, function() {
            $('.block-banner-main').css({ 'background-image': "url('" + img + "')" }).animate({ opacity: 1 });
        });

    });
}

if ($.contains(document.body, document.getElementById('header-fixed'))) {

    $(window).on("scroll", function(e) {

        var header_fixed = parseInt(Math.round($('#header-fixed').offset().top));

        if (parseInt(Math.round($(window).scrollTop())) > header_fixed) {
            $('#header-fixed').addClass('header-fixed');
        } else {
            $('#header-fixed').removeClass('header-fixed');
        }
    });
}

$('.img-link').on("click", function(event) {
    event.preventDefault();

    var image = $(this).data('img');

    $('#image-modal-gallery').attr('src', image);

    $('#modal-gallery').modal('show');
});

$('.close').on("click", function(event) {
    event.preventDefault();

    $('#modal-gallery').modal('hide');
});

$('.btn-change-list').on('click', function(event) {
    event.preventDefault();

    var not_items = $($(this).data('item')).fadeIn();

    $('.block-list-present > .container > .row > div').not(not_items).hide();

    $('.btn-change-list').removeClass('active');
    $(this).addClass('active');
});

data_dinaanim();


// intro general
setTimeout(function() {
    $('#block-introduction').fadeOut(1200, function() {
        $('#block-introduction').remove();
        $('.hidden').remove();
    });
}, 7500);

// elimino scroll
setTimeout(function() {
    $('body').removeClass('intro');
}, 7900);

// intro step by step
setTimeout(function() {
    $('.one').fadeOut('slow');
}, 400);

setTimeout(function() {
    $('.two').fadeOut('slow');
}, 800);

setTimeout(function() {
    $('.three').fadeOut('slow');
}, 1200);

setTimeout(function() {
    $('.four').fadeOut('slow');
}, 1600);

setTimeout(function() {
    $('.five').fadeOut('slow');
}, 2000);

setTimeout(function() {
    $('.six').fadeOut('slow');
}, 2400);

setTimeout(function() {
    $('.seven').fadeOut('slow');
}, 2800);

setTimeout(function() {
    $('.eight').fadeOut('slow');
}, 3200);

setTimeout(function() {
    $('.nine').fadeOut('slow');
}, 3600);

setTimeout(function() {
    $('.ten').fadeOut('slow');
}, 4000);

setTimeout(function() {
    $('.eleven').fadeOut('slow');
}, 4700);

setTimeout(function() {
    $('.twelve').fadeOut('slow');
}, 5500);

setTimeout(function() {
    $('.thirteen').fadeOut('slow');
}, 6500);

setTimeout(function() {
    $('.fourteen').fadeOut('slow');
}, 7500);
